<app-header></app-header>
<div class="two-banner-wrap">
    <div class="two-banner-slider owl-theme owl-carousel">
        <div class="two-banner-area two-banner-two">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-content">
                            <span>
                               
                                Welcome to Ginkonursery
                            </span>
                            <h1>Let's makes your office stylish</h1>
                            <div class="banner-btn">
                                <a class="cmn-banner-btn" (click)="navigate('projects')"><i class='bx bx-note'></i> Take a trip</a>
                                <a class="banner-right-btn" (click)="navigate('contactus')"><i class='bx bx-phone-call'></i> Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="two-banner-area two-banner-one">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-content">
                            <span>
                              
                                Welcome to Ginkonursery
                            </span>
                            <h1>Let's makes your office stylish</h1>
                            <div class="banner-btn">
                                <a class="cmn-banner-btn" (click)="navigate('projects')"><i class='bx bx-note'></i> Take a trip</a>
                                <a class="banner-right-btn" (click)="navigate('contactus')"><i class='bx bx-phone-call'></i> Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="two-banner-area two-banner-three">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-content">
                            <span>
                               
                                Welcome to Ginkonursery
                            </span>
                            <h1>Let's makes your office stylish</h1>
                            <div class="banner-btn">
                                <a class="cmn-banner-btn" (click)="navigate('projects')"><i class='bx bx-note'></i> Take a trip</a>
                                <a class="banner-right-btn" (click)="navigate('contactus')"><i class='bx bx-phone-call'></i> Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       

    </div>

</div>

<div id="about" class="three-about-area five-about-area pb-70"> 
    <!-- <div class="about-shape">
        <img src="assets/img/home-five/about-shape2.png" alt="About" style="opacity:0.1">
    </div> -->

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-img">
                    <img src="assets/img/about1.jpg" alt="About">
                    <img src="assets/img/leaf1.png" alt="About">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="about-content">
                    <div class="one-section-title three-section-title">
                        <span class="sub-title">About Us</span>
                        <h2>Who We Are</h2>
                    </div>

                    <div class="about-mission">


                        <div class="tab-content">
                            <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-home-tab-two">
                                <p>Ginko Nursery was established in 2017 with a vision to provide top quality and reliable landscaping services to clients from all sectors at competitive prices. Our services include design-and-build and maintenance.</p>
                                <p>At Ginko, we aim to always provide our clients guaranteed satisfaction. Our team of dedicated professionals and subject matter experts with more than 10 years of experience in horticulture and project management, go beyond
                                    merely meeting requirements.</p>

                                <p>
                                <span class="leaf">Leaf it to us</span>, as we say, for your success!</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="services" class="four-expect-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># WHAT WE DO</span>
            <h2>Our Specialities</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="expect-item" style="padding-top: 35px;">
                    <div class="expect-icon">
                        <img src="../../../assets/img/planting.png" width="44px">
                    </div>
                    <h3>Natural Landscaping</h3>
                    <!-- <a routerLink="/home-four">Read More <i class='bx bx-right-arrow-alt'></i></a> -->
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="expect-item" style="padding-top: 35px;" [routerLink]="['/artificial-landscaping']">
                    <div class="expect-icon">
                        <img src="../../../assets/img/lawn-mower.png" width="44px">
                    </div>
                    <h3>Artificial Landscaping</h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="expect-item" style="padding-top: 35px; padding-left:0; padding-right:0;" [routerLink]="['/landscape-supply-install']">
                    <div class="expect-icon">
                        <img src="../../../assets/img/landscape.png" width="44px">
                    </div>
                    <h3>Landscape Care and Maintenance</h3>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-4">
                <div class="expect-item" style="padding-top: 35px;" [routerLink]="['/landscape-supply-install']">
                    <div class="expect-icon">
                        <img src="../../../assets/img/plant.png" width="44px">
                    </div>
                    <h3>Plant Nursery</h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="expect-item" style="padding-top: 35px;">
                    <div class="expect-icon">
                        <img src="../../../assets/img/plant (1).png" width="44px">
                    </div>
                    <h3>Plant Rental Service</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="one-among-area two-among-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">WHAT WE HAVE DONE</span>

            <div>
                <h2 tooltip="This is to protect the privacy of our clients who do not wish to disclose
             any photo of their projects of private residence." flow="down">Selected Projects (Only)</h2>
            </div>


        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let project of projects | slice:0:6" [routerLink]="['/' + project.category + '/' + project.title]">
                <app-projects [project]="project"></app-projects>
            </div>
        </div>

        <div class="row">
            <p (click)="navigate('projects')" class="text-center" style="cursor: pointer;
            font-size: 18px;
            font-weight: 600;"><img src="../../../assets/img/leaf.png" width="30px;" style="margin-top:-10px;margin-right: 13px;">See All Projects</p>
        </div>
    </div>
</div>



<div class="one-counter-area three-counter-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-12 col-lg-12">
                <div class="counter-item">
                    <h4>Need Consultations?</h4>
                    <p style="margin-bottom:25px;">We Are Always With You Contact With Us.</p>
                    <div class="banner-btn">
                        <a class="cmn-banner-btn" (click)="navigate('contactus')" style="cursor:pointer;"><i class='bx bx-phone-call'></i> Contact Us</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<section class="two-lover-area pt-100 pb-70" id="testimonial">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">COUSTOMER FEEDBACKS</span>
            <h2>What People Say</h2>
        </div>

        <div class="four-step-slider owl-theme owl-carousel">

            <div class="step-item">
                <div class="lover-item">
                    <ul class="align-items-center">
                        <li>
                            <div class="lover-img">
                                <img src="assets/img/user.jpg" alt="Lover">
                                <i class='bx bxs-quote-alt-left'></i>
                            </div>
                        </li>
                        <li>
                            <div class="lover-content ">
                                <h3>Client Name 1</h3>
                                <!-- <span>Perisian Org</span> -->
                                <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt iusto nostrum quibusdam dolores consectetur voluptates beatae
                                </p>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="step-item">
                <div class="lover-item">
                    <ul class="align-items-center">
                        <li>
                            <div class="lover-img">
                                <img src="assets/img/user.jpg" alt="Lover">
                                <i class='bx bxs-quote-alt-left'></i>
                            </div>
                        </li>
                        <li>
                            <div class="lover-content">
                                <h3>Client Name 2</h3>
                                <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt iusto nostrum quibusdam dolores consectetur voluptates beatae
                                </p>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>


            <div class="step-item">
                <div class="lover-item">
                    <ul class="align-items-center">
                        <li>
                            <div class="lover-img">
                                <img src="assets/img/user.jpg" alt="Lover">
                                <i class='bx bxs-quote-alt-left'></i>
                            </div>
                        </li>
                        <li>
                            <div class="lover-content">
                                <h3>Client Name 3</h3>
                                <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt iusto nostrum quibusdam dolores consectetur voluptates beatae
                                </p>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>