<app-header></app-header>
<section class="page-title-area pos-rel" style="background-image: url(../../../../assets/img/banner2.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <div class="page-title text-center pt-180">
                    <h2>Contact Us</h2>
                    <!-- <div class="breadcrumb-list text-center">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>Contact Us</li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<div id="about" class="three-about-area five-about-area pb-70">

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/contactus.png" alt="About">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title three-section-title">
                        <h2>Contact Us</h2>
                    </div>

                    <div class="footer-item">
                        <div class="footer-address">
                            <p style="color:#212529;">No.159, Nay Kyar Street, Pearl 3rd Quarter, Mingaladon Township, Yangon, Myanmar</p>
    
                            <ul>
                                <li>
                                    <i class='bx bx-phone-call'></i>
                                    <a style="color:#212529;" href="tel:+4498886660000">09 42103 7785</a>
                                </li>
                                <li>
                                    <i class='bx bx-message-square-detail'></i>
                                    <a style="color:#212529;" href="mailto:sales1@ginkonursery.com">sales1@ginkonursery.com</a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <h2 class="formgroup">Let's Connect</h2>
                        <form name="contactForm" [formGroup]="form">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Your name*" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Your Name is required</div>
                                  </div>
                            </div>

                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Your email*" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Your Email is required</div>
                                  </div>
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Your Phone Number*" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Your Phone is required</div>
                                  </div>
                            </div>

                            <div class="form-group">
                                <textarea id="your_message" rows="4" class="form-control" placeholder="Your message" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                    <div *ngIf="f.message.errors.required">Your Message is required</div>
                                  </div>
                            </div>

                            <button type="submit" class="footer-form-btn" (click)="sendMessage()">SEND</button>
                        </form>
                        

<div class="alert" [class.visible]="isVisible"> 
  Thank You!
  <p>We will contact to you soon.</p>
</div>
                        <!-- <form [formGroup]="form">
                            <div class="form-group">
                                <input type="text" class="form-control form-names input-lg" formControlName="name" name="name" placeholder="First Name"> 
                              </div>
                            <div class="form-group">
                              <input type="text" class="form-control input-lg" formControlName="email" name="email" placeholder="Email">
                              <span class="ion-at form-control-icon"></span> 
                            </div>
                            <div class="form-group">
                                <textarea rows="5" cols="20" class="form-control input-lg" formControlName="phone" name="phone" placeholder="Phone Number"></textarea>
                                <span class="ion-edit form-control-icon"></span> 
                            </div>
                  
                            <button type="submit" class="btn btn-block btn-success" (click)="sendMessage()"> Send Message</button>
                     
                          </form> -->
            </div>
            <div class="col-md-6">
                <div class="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3815.7404984314276!2d96.14153741384307!3d16.98731021869372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c193ea2617f035%3A0x1cdd939b4c0145ab!2sGinko%20Nursery%20Co.%2C%20Ltd!5e0!3m2!1smy!2smm!4v1665424703648!5m2!1smy!2smm" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
