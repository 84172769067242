<app-header></app-header>
<section class="page-title-area pos-rel" style="background-image: url(../../../../assets/img/banner2.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <div class="page-title text-center pt-180">
                    <h2>Our Specialities</h2>
                    <!-- <div class="breadcrumb-list text-center">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>Our Specialities</li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<div class="one-among-area two-among-area pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">What We Do</span>
            <h2>Our Specialities</h2>
            </div>

        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/services/1.png" alt="Natural Landscaping">
                        <div class="among-icon">
                            <img src="assets/img/services/planting.png" alt="Among">
                        </div>
                    </div>

                    <div class="among-bottom text-center">
                        <h3>Natural Landscaping</h3>
                        <!-- <p>Newly planted flowers, grasses, trees, and shrubs should be watered frequently until they are well rooted.</p> -->
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/services/2.png" alt="Artificial Landscaping">
                        <div class="among-icon">
                            <img src="assets/img/services/lawn-mower.png" alt="Among">
                        </div>
                    </div>

                    <div class="among-bottom text-center">
                        <h3>Artificial Landscaping</h3>
                        <!-- <p>Artificial landscapes are the product of human thought and arise as a result of human activities.</p> -->
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/services/3.png" alt="Landscape Care and Maintenance">
                        <div class="among-icon">
                            <img src="assets/img/services/landscape.png" alt="Among">
                        </div>
                    </div>

                    <div class="among-bottom text-center">
                        <h3>Landscape Care and Maintenance</h3>
                        <!-- <p>Parks and gardens for private and public housing, public and semi-public buildings.</p> -->
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/services/4.png" alt="Plant Nursery">
                        <div class="among-icon">
                            <img src="assets/img/services/plant.png" alt="Among">
                        </div>
                    </div>

                    <div class="among-bottom text-center">
                        <h3>Plant Nursery</h3>
                        <!-- <p>A Nursery that raises, propagates and sells seedlings and other planting materials.</p> -->
                    </div>
                </div>
            </div>
            
            <div class="col-md-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/services/5.jpg" alt="Plant Rental Service">
                        <div class="among-icon">
                            <img src="assets/img/services/plant (1).png" alt="Among">
                        </div>
                    </div>

                    <div class="among-bottom text-center">
                        <h3>Plant Rental Service</h3>
                        <!-- <p>Divided home equity by paying sit amet seeturlit sed dolor se equity </p> -->
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>
<app-footer></app-footer>