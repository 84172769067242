<footer class="one-footer-area">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-address">
                        <h3>Contact Address</h3>
                        <p>No.159, Nay Kyar Street, Pearl 3rd Quarter, Mingaladon Township, Yangon, Myanmar</p>

                        <ul>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+4498886660000">09 42103 7785</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:sales1@ginkonursery.com">sales1@ginkonursery.com</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item footer-item-link">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a (click)="navigate('')">About Us</a></li>
                            <li><a (click)="navigate('services')">Services</a></li>
                            <li><a (click)="navigate('projects')">Projects</a></li>
                            <li><a (click)="navigate('contactus')">Contact Us</a></li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <h3>Connect with Us</h3>
                    <div class="footer-logo">

                        <ul>
                            <li><a href="https://www.facebook.com/ginkonursery" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/ginkonursery/?hl=en" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-slack'></i></a></li>

                        </ul>
                    </div>
                </div>
            </div>


        </div>

        <div class="copyright-area">
            <div class="row align-items-center">

                <div class="col-lg-12">
                    <div class="copyright-item">
                        <p>Copyright @2022 Ginko Nursery. All Right Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>