<nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">

            <li class="nav-item"><a class="nav-link" (click)="navigate('/')" id="aboutus">ABOUT US</a></li>

            <li class="nav-item"><a class="nav-link" (click)="navigate('services')" [ngClass]="{'active' : routeLink.includes('services')}">SERVICES</a></li>

            <li class="nav-item"><a class="nav-link" (click)="navigate('projects')" [ngClass]="{'active' : routeLink.includes('projects')}">PROJECTS</a></li>

            <li class="nav-item"><a class="nav-link" href="#testimonial" [ngClass]="{'active' : routeLink.includes('#')}">TESTIMONIALS</a></li>

            <li class="nav-item"><a class="nav-link" (click)="navigate('contactus')" [ngClass]="{'active' : routeLink.includes('contactus')}">CONTACT US</a></li>
        </ul>
    </div>

    <!-- <div class="side-nav">
        <a class="side-nav-right cd-signup" routerLink="/"><i class='bx bx-link-external'></i>SUBSCRIBE</a>
    </div> -->
</nav>